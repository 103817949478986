<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import WebsiteScriptModal from "@/components/modals/websiteScriptModal";

import { mapGetters } from 'vuex';

export default {
    components: {
        Layout,
        PageHeader,
        WebsiteScriptModal
    },

    async mounted() {
        await this.getWebsiteMetaData();
        await this.fetchFeatureList();
    },

    data() {
        return {
            title: "Meta Data",
            items: [],
            websiteMetaDataItems: [],
            selectedFeature: null,
            features: [],
            metaDataStatus: null,
            websiteInfo: {}
        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId'
        ])
    },

    methods: {
        async getWebsiteMetaData() {
            console.log('!!!!getting meta data for website', this.selectedFeature?.id);
            api.getWebsiteMetaData(this.selectedWebisteId, this.selectedFeature?.id, this.metaDataStatus)
                .then(response => {
                    console.log(response);
                    this.websiteMetaDataItems = response.data.metaData;
                });
        },

        async fetchFeatureList() {
            api.websiteFeatureList()
                .then(response => {

                    console.log(response);

                    this.features = response.data.features;
                    console.log(this.features);


                })
                .catch(e => {
                    console.log(e);
                })
        },

        async onFeatureChanged(e) {
            let featureId = Number(e.target.value);

            if (isNaN(featureId)) {
                this.currentFeature = { id: '', groups: {} };
                return
            }

            this.selectedFeature = this.features.find(e => e.id === featureId);
            console.log('selectedFeature changed', this.selectedFeature);
            await this.getWebsiteMetaData();
        },

        async onStatusChange(e) {
            if (e.target.value === 'Any Status') {
                this.metaDataStatus = null;
            } else {
                this.metaDataStatus = e.target.value;
            }

            await this.getWebsiteMetaData();
        },

        onPublish(metaData) {
            this.changeMetaDataStatus(metaData, "Published");
        },

        onDraft(metaData) {
            this.changeMetaDataStatus(metaData, "draft");
        },

        changeMetaDataStatus(metaDataObj, status) {
            var data = {};
            data.websiteId = this.selectedWebisteId;
            data.webpageId = metaDataObj.page.id;
            data.featureId = metaDataObj.feature.id;
            data.status = status;

            api.addWebPagMetaData(data)
                .then(response => {
                    metaDataObj.status = response.data.publishStatus;
                });
        },

        navigateToEditMetaData(dataObj) {
            this.$router.push({
                name: "metadata-page",
                params: { websiteId: `${this.selectedWebisteId}`, pageId: `${dataObj.page.id}`, path: `${dataObj.page.path}`, featureId: dataObj.feature.id }
            });

            console.log(dataObj);
        },

        getScript() {
            api.getWebsiteInfo(this.selectedWebisteId)
                .then(response => {
                    console.log("website info response:", response.data);
                    this.websiteInfo = response.data;
                })
        }
    }
}
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row">
            <div class="col">
                <div id="websiteInfo" class="modal fade" tabindex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                    <WebsiteScriptModal :websiteInfo="websiteInfo"></WebsiteScriptModal>
                </div>

                <div class="card">
                    <div class="card-header align-items-center">

                        <p class="
                          text-uppercase
                          fw-bold
                          text-truncate
                          mb-0
                        ">Overview of Page Metadata</p>

                        <div class="row mt-3">
                            <div class="col">
                                <h3>Filter by:</h3>
                            </div>
                            <div class="col text-end me-4" data-bs-toggle="modal" data-bs-target="#websiteInfo">
                                <a href="#" @click="getScript">Get script</a>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <select @change="onFeatureChanged" class="form-select ">
                                    <option>All features</option>
                                    <option v-for="feature in features" :value="feature.id" :key="feature.id">
                                        {{ feature.label }}</option>
                                </select>
                            </div>
                            <div class="col">

                                <select @change="onStatusChange" class="form-select">
                                    <option>Any Status</option>
                                    <option>Draft</option>
                                    <option>Published</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- end card header -->
                    <div class="card-body">
                        <div class="table-responsive table-card m-1">
                            <table class="table align-middle table-nowrap">
                                <thead class="table-light text-muted">
                                    <tr>
                                        <th scope="col">Select</th>
                                        <th class="sort" scope="col">Path</th>
                                        <th scope="col">Feature</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <!--end thead-->
                                <tbody class="list form-check-all">
                                    <tr v-for="(data, index) of websiteMetaDataItems" :key="index">
                                        <td><input type="checkbox" /></td>
                                        <td class="id" @click="navigateToEditMetaData(data)">
                                            {{ data.page.path }}
                                        </td>

                                        <td>
                                            {{ data.feature.label }}
                                        </td>

                                        <td>
                                            {{ data.status }}
                                        </td>
                                        <td> <button v-if="data.status === 'draft'" @click="onPublish(data)"
                                                class="btn btn-secondary">Publish live</button>
                                            <button v-if="data.status === 'published'" @click="onDraft(data)"
                                                class="btn btn-secondary">Remove from live</button>
                                        </td>
                                    </tr>
                                </tbody>
                                <!--end tbody-->
                            </table>
                        </div>
                        <!-- <div class="d-flex justify-content-end mt-3">
                                    <div class="pagination-wrap hstack gap-2">
                                        <a class="page-item pagination-prev disabled" href="#" v-if="page != 1"
                                            @click="page--">
                                            Previous
                                        </a>
                                        <ul id="pageIndex" class="pagination listjs-pagination mb-0">
                                            <li :class="{
                                              active: pageNumber == page,
                                              disabled: pageNumber == '...',
                                            }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                                                :key="index" @click="page = pageNumber">
                                                <a class="page" href="#">{{ pageNumber }}</a>
                                            </li>
                                        </ul>
                                        <a class="page-item pagination-next" href="#" @click="page++"
                                            v-if="page < pages.length">
                                            Next
                                        </a>
                                    </div>
                                </div> -->
                    </div>
                    <!--end card body-->
                </div>
                <!--end card-->
            </div>
        </div>

    </Layout>
</template>
<script>

export default {


    props: {
        websiteInfo: {
            type: Object
        }
    },

    data() {
    },

    methods: {
    
    }
}
</script>
 
<template>
    <!-- Default Modals -->
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="myModalLabel">Website script</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
            </div>
            <div class="modal-body">
                <p>Copy and paste this into the page you would like to add your structured data to.</p>
                <pre>
                        {{ websiteInfo.managerScript }}
                    </pre>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
            </div>

        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</template>


